import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "styled-components";
import MailInput from "./components/MailInput";
import landingPageBg from "./images/landing-page-bg.png";
import ywbLogo from "./images/ywb-logo.png";
import jsonp from "jsonp";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const LeftPane = styled.div`
  flex: 0.25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  padding-top: 131px;
  padding-bottom: 131px;
  background-image: url(${landingPageBg});
  background-size: cover;
  background-position: center;
  position: relative;

  /* Create the overlay using ::before */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9); /* Black overlay with 50% opacity */
    z-index: 1; /* Ensure the overlay appears above the background image */
  }

  /* Ensure that any child elements are above the overlay */
  * {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    padding-bottom: 50px;
    padding-top: 100px;
  }
`;

const Logo = styled.img`
  width: 81px;
  height: 81px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Title = styled.h1`
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 0;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Subtitle = styled.h2`
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 25px;
  margin-top: 8px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Url = styled.a`
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  width: 100%;
  text-align: center;
`;

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.75;
  background-color: #0a0a0a;
  padding: 70px 20%;
  position: relative;

  @media (max-width: 768px) {
    flex: 1;
    padding: 50px 10%;
  }

  /* For the dashed border right */
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-image: linear-gradient(#fff 50%, rgba(255, 255, 255, 0) 0%);
    background-size: 5px 20px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const ContentTitle = styled.h3<{ space?: "small" | "none" }>`
  color: #fc6600;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 300;
`;

const ContentText = styled.p`
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 300;
`;

function App() {
  const [formState, setFormState] = useState({ email: "", fullName: "" });
  const [emailComplete, setEmailComplete] = useState(false);
  const [nameComplete, setNameComplete] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [streamedMessage, setStreamedMessage] = useState("");
  const [streamedConclusion, setStreamedConclusion] = useState("");
  const [isStreamMessageComplete, setIsStreamMessageComplete] = useState(false);
  const [isStreamConclusionComplete, setIsStreamConclusionComplete] =
    useState(false);

  const message = ` We are thrilled to bring you some super exciting news. <br /><br />
Your Work Buddy is launching soon, becoming your ultimate resource for on-the-job support designed specifically for tech professionals.<br /><br />

Your work day is about to get a whole lot easier, with a little help from us! We cannot wait to improve your day to day working experience. <br /><br />

Next Up: Be the first to get updates on our official launch countdown and more! Just share your name and email below to stay in the loop.`;

  const conclusion = `thank you for your patience and excitement! more news will be headed your way soon! <br /><br />
  best regards, <br /> Your Work Buddy Team`;

  const emailInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const MAILCHIMP_URL =
    "https://yourworkbuddy.us22.list-manage.com/subscribe/post?u=ab0eaae5716d0c8a5f96eb5bb&amp;id=1be485a9a7&amp;f_id=00f3c2e1f0"; // Replace with your Mailchimp URL

  const handleNameComplete = () => {
    if (!formState.fullName) return toast.error("Please enter your full name");
    setNameComplete(true);
    setShowNameInput(false);
    setShowEmailInput(true);
    emailInputRef.current?.focus();
  };

  const handleEmailComplete = () => {
    if (!formState.email) return toast.error("Please enter your email");
    if (!formState.email.includes("@") || !formState.email.includes("."))
      return toast.error("Please enter a valid email");

    // Prepare data for Mailchimp
    const url = `${MAILCHIMP_URL}&EMAIL=${encodeURIComponent(
      formState.email
    )}&FNAME=${encodeURIComponent(formState.fullName)}`;

    // Submit to Mailchimp using JSONP
    jsonp(url, { param: "c" }, (err, data) => {
      if (err) {
        toast.error("Subscription failed. Please try again.");
      } else if (data.result === "error") {
        toast.error(data.msg);
      } else {
        setEmailComplete(true);
        setShowEmailInput(false);
        toast.success("Thank you for subscribing!");
      }
    });
  };

  const STREAM_SPEED = 12.5;

  useEffect(() => {
    let index = 0; // Start from the second word
    setStreamedMessage("Hi Buddy! "); // Explicitly set the first word

    const intervalId = setInterval(() => {
      if (index < message.length) {
        setStreamedMessage((prev) => prev + (message[index] || ""));
        index++; // Increment index after appending each character
      } else {
        clearInterval(intervalId);
        setShowNameInput(true); // Show name input after stream completes
        setIsStreamMessageComplete(true); // Mark stream complete
      }
    }, STREAM_SPEED);

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [message]);

  useEffect(() => {
    if (emailComplete) {
      let index = 5; // Start from the second word
      setStreamedConclusion("thank "); // Explicitly set the first word

      const intervalId = setInterval(() => {
        if (index < conclusion.length) {
          setStreamedConclusion((prev) => prev + (conclusion[index] || ""));
          index++; // Increment index after appending each character
        } else {
          clearInterval(intervalId);
          setShowNameInput(true); // Show name input after stream completes
          setIsStreamConclusionComplete(true); // Mark stream complete
        }
      }, STREAM_SPEED);

      return () => clearInterval(intervalId); // Clear the interval on unmount
    }
  }, [conclusion, emailComplete]);

  return (
    <div>
      <Wrapper>
        <LeftPane>
          <Logo src={ywbLogo} alt="Your Work Buddy" />
          <TextWrapper>
            <Title>Connecting Expertise!</Title>
            <Subtitle>
              one project <br /> at a time.
            </Subtitle>
          </TextWrapper>
          <Url href="https://www.yourworkbuddy.com">www.yourworkbuddy.com</Url>
        </LeftPane>

        <RightPane>
          <ContentTitle>buddy</ContentTitle>

          <ContentText>
            {<div dangerouslySetInnerHTML={{ __html: streamedMessage }} />}
          </ContentText>

          {isStreamMessageComplete && (
            <>
              <ContentTitle style={{ marginBottom: 0, marginTop: "20px" }}>
                you
              </ContentTitle>
              <ContentText style={{ marginTop: "5px" }}>
                {formState.fullName}
              </ContentText>
            </>
          )}

          {showNameInput && isStreamMessageComplete && !emailComplete && (
            <MailInput
              ref={nameInputRef}
              placeholder="Enter your name"
              type="name"
              autocomplete="name"
              onClick={handleNameComplete}
              value={formState.fullName}
              setValue={(e) => setFormState({ ...formState, fullName: e })}
              showArrow={!nameComplete}
            />
          )}

          {nameComplete && (
            <>
              <ContentTitle style={{ marginBottom: 0, marginTop: "20px" }}>
                you
              </ContentTitle>
              <ContentText style={{ marginTop: "5px" }}>
                {formState.email}
              </ContentText>
            </>
          )}

          {showEmailInput && (
            <MailInput
              ref={emailInputRef}
              placeholder="Enter your email"
              type="email"
              autocomplete="email"
              onClick={handleEmailComplete}
              value={formState.email}
              setValue={(e) => setFormState({ ...formState, email: e })}
              showArrow={!emailComplete}
            />
          )}

          {emailComplete && nameComplete && (
            <>
              <ContentTitle style={{ marginTop: "30px", marginBottom: 0 }}>
                buddy
              </ContentTitle>

              <ContentText>
                {
                  <div
                    dangerouslySetInnerHTML={{ __html: streamedConclusion }}
                  />
                }
              </ContentText>
            </>
          )}
        </RightPane>
      </Wrapper>
      <ToastContainer />
    </div>
  );
}

export default App;
